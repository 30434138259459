import React from 'react';
import PropTypes from 'prop-types';

const Flex = props => {
	const { children, className, alignItems, justifyContent, mobileFlex, flexDirection, gap } = props;
	const getFlexResponsive = () => mobileFlex ? 'd-flex' : 'd-md-flex';
	return (
		<div
			className={`${getFlexResponsive()} ${className} ${flexDirection ? 'flex-' + flexDirection : ''} ${alignItems ? 'align-items-' + alignItems : ''} ${justifyContent ? 'justify-content-' + justifyContent : ''}`}
			style={{ gap: gap }}
		>
			{children}
		</div>
	);
}

Flex.propTypes = {
	className: PropTypes.string,
	alignItems: PropTypes.string,
	flexDirection: PropTypes.string,
	justifyContent: PropTypes.string,
	mobileFlex: PropTypes.bool,
	gap: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
};

Flex.defaultProps = {
	mobileFlex: true,
	flexDirection: 'row',
	className: '',
	gap: '0',  // Default gap if none provided
};

export default Flex;
